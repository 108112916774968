<template>
  <b-modal
    id="modal-delete"
    ok-variant="danger"
    modal-class="modal-danger"
    ref="modal-delete"
    centered
    title="Deletar"
  >
    <b-card-text>
      <p>Você está preste a deletar este dado do sistema: <b>{{deleteData.nome}}</b></p>
      Por favor, confirme a exclusão antes de prosseguir.
      <b-form-checkbox
        id="checkbox-1"
        @change="newDeleteStatus"
        v-model="DeleteStatus"
        name="checkbox-1"
        value="accepted"
      >
        Concordo em excluir
      </b-form-checkbox>
    </b-card-text>
    <template #modal-footer>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="outline-danger"
          block
          :disabled="DeleteStatus == false"
          @click="deleteModules()"
        >
          Deletar
        </b-button>
      </b-col>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      // Variáveis Global
      DeleteStatus: false,

      deleteData: {
        nome: null,
        id: null,
        pathAPI: null,
      }
      
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"])
  },
  methods: {
    newDeleteStatus(value) {
      this.DeleteStatus = value;
    },

    deleteModules(){
      let data = this.deleteData
      this.$store
        .dispatch("connectAPI/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.DeleteStatus = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$emit('fetch-all');
          this.$refs['modal-delete'].hide()
        })
    }
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
          this.deleteData.id = this.dataProp.id;
          this.deleteData.nome = this.dataProp.nome;
          this.deleteData.pathAPI = this.dataProp.pathAPI;
      }
    }
  },
  created() {},
};
</script>
