<template>
  <b-modal
    id="modal-import"
    ok-variant="primary"
    modal-class="modal-primary"
    ref="modal-import"
    centered
    title="Importar arquivo"
  >
    <b-card-text>
      <b-form-group label="* Arquivo:" label-for="i-nome" class="pl-1 pr-1">
        <b-form-file
          v-model="deleteData.excel"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
        />
      </b-form-group>
    </b-card-text>
    <template #modal-footer>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="mt-2"
          variant="outline-primary"
          block
          @click="importModules"
        >
          Importar
        </b-button>
      </b-col>
    </template>
  </b-modal>
</template>
<script>
/* eslint-disable */
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  components: {
    mapActions,
    mapState,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      deleteData: {
        excel: undefined,
        pathAPI: null,
      }
    };
  },
  computed: {
    ...mapState("connectAPI", ["modules"])
  },
  methods: {
    importModules(){
      let data = this.deleteData
      this.$store
        .dispatch("connectAPI/import_data", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Importado com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'success'
            }
          })
          this.$emit('fetch-all');
          this.$refs['modal-import'].hide()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
         
        })
    }
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
          this.deleteData.pathAPI = this.dataProp.pathAPI;
      }
    }
  },
  created() {},
};
</script>